<template>
  <div class="tableList toevtlist">
    <div class="header">考评指标
      <div class="exportData">
        <el-button class="textBtn" @click="AddRework" type="text">+新增</el-button>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" ref="tabledeatailData" max-height="350"   style="width: 100%">
        <el-table-column prop="sequenceNumber" label="序号" width="80px">
        </el-table-column>
        <el-table-column prop="evaluationCode" label="考评指标代码" >
        </el-table-column>
        <el-table-column prop="evaluationName" label="考评指标描述" >
        </el-table-column>
        <el-table-column prop="weight" label="权重比率(%)" width="130px">
          <template #default="scope">
            <el-input :disabled=scope.row.showDisabled v-model="scope.row.weight"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="cjZs" label="次级指标" width="140px">
          <template #default="scope">
            <el-button size="mini" type="text" @click="SecondaryIndicators(scope.row)">次级指标</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100px">
          <template #default="scope">
            <el-button v-if="scope.row.edStart === 1" type="text" size="mini" @click="edit(scope.row)">编辑</el-button>
            <el-button v-else type="text" size="mini" @click="preservation(scope.row)">保存</el-button>
            <el-button type="text" size="mini" @click="eliminateDinlog(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <dialog-box ref="AddNewData" componentName="EvaluateIndexDialog" dialogWidth="800px" :dialogShow="AddcheckDialogShow" @handleClose='handleClose' title="新增" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'addPrework', type:'primary', size:'mini'}]" @addPrework="peworkBtn"></dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
export default {
  components: Component.components,
  name: 'Evaluatetable',
  props: ['newParnData'],
  data () {
    return {
      parentId: '',
      AddcheckDialogShow: false,
      tableData: []
    }
  },
  created () {
  },
  methods: {
    // 调用模板组件中的方法
    getActive () {
      this.$emit('pf')
    },
    // 新增弹框
    AddRework () {
      this.AddcheckDialogShow = true
    },
    // 点击新增里面的确认按钮
    peworkBtn () {
      var newsArr = this.$refs.AddNewData.$refs.content.form
      newsArr.edStart = 1
      newsArr.showDisabled = true
      if (!newsArr.sequenceNumber) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入序号'
        })
        return false
      }
      if (!newsArr.evaluationCode) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入考评指标代码'
        })
        return false
      }
      if (!newsArr.evaluationName) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入考评指标描述'
        })
        return false
      }
      if (!newsArr.weight) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入权重比率'
        })
        return false
      }

      const obj = {
        parentId: this.parentId,
        sequenceNumber: newsArr.sequenceNumber,
        evaluationCode: newsArr.evaluationCode,
        evaluationName: newsArr.evaluationName,
        weight: newsArr.weight
      }
      request('/api/evaluation/index/save', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '添加成功'
          })
        }
        this.getActive()
      })
      this.AddcheckDialogShow = false
    },
    // 保存
    preservation (row) {
      if (!row.weight) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入权重比率'
        })
        return false
      }
      const obj = {
        ...row
      }
      request('/api/evaluation/index/update', 'POST', obj).then((res) => {
        if (res.code === '200') {
          row.edStart = 1
          row.showDisabled = true
          this.$message({
            showClose: true,
            type: 'success',
            message: '修改成功'
          })
          // this.getActive()
        }
      })
    },
    // 编辑
    edit (row) {
      row.edStart = 2
      row.showDisabled = false
    },

    // 删除
    eliminate (row) {
      request('/api/evaluation/index/delete?id=' + row.id, 'post').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功'
          })
          this.getActive()
        }
      })
    },
    // 删除弹框
    eliminateDinlog (row) {
      this.$confirm('确认要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.eliminate(row)
      })
    },
    // 关闭弹窗
    handleClose () {
      this.AddcheckDialogShow = false
    },
    // 跳转次级指标
    SecondaryIndicators (data) {
      this.$router.push({
        params: { viewName: 'SubcriteriAallot' },
        query: {
          id: data.id,
          code: data.evaluationCode,
          name: data.evaluationName
        }
      })
    }
  },
  watch: {
    'newParnData' (newData) {
      newData.two.forEach(item => {
        item.edStart = 1
        item.showDisabled = true
      })
      this.tableData = newData.two
      this.parentId = newData.id
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
@import '@/assets/css/elForm.scss';
.toevtlist{
  .header{
    position: relative;
    .exportData{
      position: absolute;
      left: 90px;
      top: 0;
    }
  }
}
.tableList {
  .table{
    :deep(.el-table td) {
        padding: 4px 0;
    }
    :deep(.el-input__inner){
      height:34px;
      line-height: 34px;
    }
  }
  .is-disabled{
    :deep(.el-input__inner){
      background: none;
      border: none;
      color: #606266;
      padding: 0;
    }
    :deep(.el-select__caret){
      display: none;
    }
  }
}
</style>
